import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  BackgroundBlur: Color.Surface.Base.Background,
  Background: Color.Surface.Base.Background,

  Icon: Color.TextColors.BodyText + 'D0',
  IconFocus: Color.TextColors.BodyText,
  IconFocusCornerRadius: Color.CornerRadius.Large,

  NotificationBackground: Color.Primitive.Accent,
  NotificationText: Color.Primitive.AccentContrast,
};
