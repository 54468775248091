import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Breakpoint, Color, DynamicValues, FontTextSize, ZIndex } from '@pafcloud/style';
import { Logo } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { Burger } from '../../../header-content/HeaderContent.clean';
import { HeaderIconButton } from '../../../header-icon-button';
import { SidebarVerificationSection } from '../sidebar-verification-section';
import type { SidebarProps } from './Sidebar';

const contentFragment = graphql`
  fragment SidebarClean_content on Query {
    ...SidebarVerificationSection_content
  }
`;

const Nav = styled.nav<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'fixed',
  top: DynamicValues.GamePauseHeight,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingBottom: 32,
  overflowY: 'auto',
  zIndex: ZIndex.Header + 1,
  transition: 'transform 0.2s',
  transform: isOpen ? undefined : `translateX(100%)`,
  backgroundColor: Color.Layout.Sidebar.Background,
  fontSize: FontTextSize.Big,
  color: Color.Layout.Sidebar.Foreground,

  '::-webkit-scrollbar': {
    display: 'none',
  },

  [Breakpoint.LaptopOrLarger]: {
    width: 300,
    '::-webkit-scrollbar': {
      display: 'unset',
    },
  },
}));

const SidebarHeader = styled.header({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 8px',
});

const SidebarHeaderLogo = styled(Logo)({
  height: 36,
  margin: 8,
});

const CloseSidebarButton = styled(HeaderIconButton)({
  ':after': {
    position: 'absolute',
    zIndex: -1,
    width: 32,
    height: 32,
    borderRadius: '99vw',
    content: '""',
    backgroundColor: Color.Primitive.SecondaryContrast,
  },

  [`${Burger} > span, ${Burger} ::before, ${Burger} ::after`]: {
    background: Color.Primitive.Accent,
  },
});

export const Sidebar = forwardRef<HTMLElement, SidebarProps>(({ onClose, isOpen, children, content }, ref) => {
  const { t } = useTranslation('header');
  const data = useFragment(contentFragment, content);

  return (
    <Nav isOpen={isOpen} ref={ref} data-testid="main-menu">
      <SidebarHeader>
        <SidebarHeaderLogo inverse />
        <CloseSidebarButton onClick={onClose} aria-label={t('main-menu-button.close.aria-label')}>
          <Burger open />
        </CloseSidebarButton>
      </SidebarHeader>

      <SidebarVerificationSection content={data} />
      {children}
    </Nav>
  );
});

Sidebar.displayName = 'Sidebar';
