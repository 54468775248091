import type { FC } from 'react';
import { useRef } from 'react';
import shouldForwardProp from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint, FontTextSize } from '@pafcloud/style';
import { ButtonLink, Link, Logo } from '@pafcloud/base-components';

import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { useIsLoggedIn, useWallet } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { MessageMenu } from '../menus/message-menu';
import { BurgerComponent } from '../BurgerComponent';
import { HeaderIconButton } from '../header-icon-button';
import type { HeaderContentSpeedy_content$key } from './__generated__/HeaderContentSpeedy_content.graphql';
import { StyledHeader } from './shared';
import type { HeaderContentProps } from './shared';
import { HeaderMenu } from './HeaderMenu';
import { HeaderBackground } from './HeaderBackground';

const contentFragment = graphql`
  fragment HeaderContentSpeedy_content on Query {
    ...HeaderMenu_content
  }
`;

const Header = styled(StyledHeader)({
  padding: '0 8px 0 16px',
  justifyContent: 'space-between',
  boxShadow: 'unset',
  '& > div': {
    flexBasis: '20%',
  },
  [Breakpoint.LaptopOrLarger]: {
    justifyContent: 'center',
  },
  backgroundColor: 'unset',
});

const LeftSection = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
});

const MiddleSection = styled.div({
  display: 'none',
  fontSize: FontTextSize.Big,
  fontWeight: 'bold',

  [Breakpoint.LaptopOrLarger]: {
    flexGrow: 2,
    display: 'flex',
    justifyContent: 'center',
  },
});

const RightSection = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const Burger = styled(BurgerComponent)({
  transform: 'scale(0.85)',
});

const LogoLink = styled(Link)({
  display: 'flex',
});

const MobileLogo = styled(Logo)({
  height: 36,

  [Breakpoint.LaptopOrLarger]: {
    display: 'none',
  },
});

const DesktopLogo = styled(Logo)({
  height: 36,
  display: 'none',

  [Breakpoint.LaptopOrLarger]: {
    display: 'block',
  },
});

const HeaderButtonLink = styled(ButtonLink, { shouldForwardProp })<{ isLoggedIn?: boolean }>(({ isLoggedIn }) => ({
  fontSize: isLoggedIn ? FontTextSize.Tiny : FontTextSize.Normal,
  width: '100%',
  maxWidth: 96,
  maxHeight: 40,
  margin: '8px 0',
}));

export const HeaderContent: FC<HeaderContentProps> = ({ isMainMenuOpen, setMainMenuOpen, ...props }) => {
  const content = useFragment<HeaderContentSpeedy_content$key>(contentFragment, props.content);
  const { t } = useTranslation(['header', 'profile']);
  const { balance } = useWallet();
  const isLoggedIn = useIsLoggedIn();
  const headerRef = useRef<HTMLDivElement>(null);
  const menuAriaLabel = isMainMenuOpen ? t('main-menu-button.close.aria-label') : t('main-menu-button.open.aria-label');
  const { getFlowUrl } = useFlowRouter();

  const formatAmount = useFormatAmount();

  return (
    <Header ref={headerRef}>
      <HeaderBackground />

      <LeftSection>
        <LogoLink aria-label={t('logo.aria-label')} href="/">
          <MobileLogo variant="icon" />
          <DesktopLogo variant="horizontal" />
        </LogoLink>
      </LeftSection>
      <MiddleSection>
        <HeaderMenu content={content} />
      </MiddleSection>
      <RightSection>
        {isLoggedIn && (
          <>
            <MessageMenu />
            {balance != null && (
              <HeaderButtonLink
                href={getFlowUrl('deposit')}
                aria-label={t('header-button.logged-in.aria-label')}
                type="button"
                variant="primary"
                size="default"
                isLoggedIn
                data-testid="header-action"
              >
                {formatAmount(balance)}
              </HeaderButtonLink>
            )}
          </>
        )}
        {!isLoggedIn && (
          <HeaderButtonLink
            href={getFlowUrl('deposit')}
            aria-label={t('header-button.logged-out.aria-label')}
            size="default"
            variant="primary"
            type="button"
            data-testid="header-action"
          >
            {t('header-button.logged-out.text')}
          </HeaderButtonLink>
        )}

        <HeaderIconButton
          onClick={() => setMainMenuOpen(!isMainMenuOpen)}
          aria-label={menuAriaLabel}
          aria-expanded={isMainMenuOpen}
          data-name="main-menu-button"
        >
          <Burger open={isMainMenuOpen} />
        </HeaderIconButton>
      </RightSection>
    </Header>
  );
};
