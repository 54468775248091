import { Color } from '@pafcloud/style';

// The menu on clean theme is inverted this is why foreground and background color has been swapped

export const Colors = {
  Text: Color.Surface.Base.Background,
  TextHover: Color.Surface.Base.Background,
  TextActive: Color.Surface.Base.Background,
  TextShadow: undefined,

  Icon: Color.Surface.Base.Background,
  IconActive: Color.Primitive.Accent,

  BackgroundHover: 'rgba(0,0,0,0.25)',
  BackgroundActive: 'rgba(0,0,0,0.25)',

  ItemDivider: undefined,
  ItemActiveShadow: `inset 4px 0 0 ${Color.Primitive.Primary}`,

  SubMenuBackground: 'rgba(0,0,0,0.10)',
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 800,
  WeightHover: 800,
  WeightActive: 800,
} as const;
