import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { HeaderMenuLink as HeaderMenuLink_default } from './HeaderMenuLink.default';
import { HeaderMenuLink as HeaderMenuLink_dreams } from './HeaderMenuLink.dreams';
import { HeaderMenuLink as HeaderMenuLink_momentum } from './HeaderMenuLink.momentum';
import { HeaderMenuLink as HeaderMenuLink_momentum_neon } from './HeaderMenuLink.momentum-neon';
import { HeaderMenuLink as HeaderMenuLink_goldenV2 } from './HeaderMenuLink.goldenV2';
import { HeaderMenuLink as HeaderMenuLink_clean_blue } from './HeaderMenuLink.clean-blue';

export const HeaderMenuLink = withBuildEnv(() => {
  if ($buildEnv.theme === 'dreams') {
    return HeaderMenuLink_dreams;
  }

  if ($buildEnv.theme === 'momentum') {
    return HeaderMenuLink_momentum;
  }

  if ($buildEnv.theme === 'momentum-neon') {
    return HeaderMenuLink_momentum_neon;
  }

  if ($buildEnv.theme === 'goldenV2') {
    return HeaderMenuLink_goldenV2;
  }

  if ($buildEnv.theme === 'clean-blue') {
    return HeaderMenuLink_clean_blue;
  }

  return HeaderMenuLink_default;
});
