import { Color } from '@pafcloud/style';
import { Colors as Color_default } from './colors.default';

export const Colors = {
  ...Color_default,
  BulletDone: Color.Primitive.Primary,
  FallbackIconBackground: Color.Primitive.Primary,
  FallbackIcon: Color.Primitive.PrimaryContrast,
  TurnoverBorder: `1px solid ${Color.Surface.Base.Dimmed}`,
  TurnoverBackground: Color.Surface.Base.Background,
};
