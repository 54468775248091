import styled from '@emotion/styled';
import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import { useRouter } from 'next/router';
import type { HeaderMenu_content$key } from './__generated__/HeaderMenu_content.graphql';
import { HeaderMenuLink } from './HeaderMenuLink';

const contentFragment = graphql`
  fragment HeaderMenu_content on Query {
    site {
      headerMenu {
        links {
          text
          url
        }
      }
    }
  }
`;

type Props = {
  content: HeaderMenu_content$key;
  className?: string;
};

const HeaderNav = styled.nav({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
  width: 'max-content',
  margin: '0 auto',
});

export const HeaderMenu: FC<Props> = (props) => {
  const router = useRouter();
  const currentPage = router.asPath;
  const content = useFragment(contentFragment, props.content);
  if (content.site.headerMenu == null) {
    return null;
  }

  const { links } = content.site.headerMenu;

  if (links.length === 0) {
    return null;
  }

  return (
    <HeaderNav className={props.className}>
      {links.map((link, index) => (
        <HeaderMenuLink key={index} href={link.url} aria-current={currentPage.startsWith(link.url)} title={link.text}>
          {link.text}
        </HeaderMenuLink>
      ))}
    </HeaderNav>
  );
};
