/**
 * @generated SignedSource<<67266e5585c61f5cec1ebe23eebfcd85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderContentSpeedy_content$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeaderMenu_content">;
  readonly " $fragmentType": "HeaderContentSpeedy_content";
};
export type HeaderContentSpeedy_content$key = {
  readonly " $data"?: HeaderContentSpeedy_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContentSpeedy_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderContentSpeedy_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderMenu_content"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4b68f77c520b15476e37d04015734ff8";

export default node;
